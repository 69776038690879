import { graphql } from "gatsby";
import React from "react";
import Layout from "~components/layouts/Layout";
import FeatureList from "~components/sections/landingpages/FeatureList";
import KeySellingpoints from "~components/sections/landingpages/KeySellingpoints";
import LandingpageHeader from "~components/sections/landingpages/LandingpageHeader";
import SeoText from "~components/sections/landingpages/SeoText";
import Cta from "~components/sections/Cta";
import About from "~components/sections/landingpages/About";
import SchemaProduct from "~components/layouts/schemas/SchemaProduct";

export default function Page({ data, location }) {
	const keyword = "Webdesign für Zahnärzte";
	const title = "Webdesign für Zahnärzte als patientenorientierter Website";
	const description =
		"Mit Webdesign für Zahnärzte entwickeln wir Dir eine patientenorientierter Website, mit der Du an Sichtbarkeit und Zeit gewinnst.";

	return (
		<Layout
			location={location}
			title={title}
			desc={description}
			imageFacebook={`${data.site.siteMetadata.siteUrl}${data.headerImage.facebook.gatsbyImageData.images.fallback.src}`}
			imageFacebookWidth={data.headerImage.facebook.gatsbyImageData.width}
			imageFacebookHeight={data.headerImage.facebook.gatsbyImageData.height}
			imageTwitter={`${data.site.siteMetadata.siteUrl}${data.headerImage.twitterAndProduct.gatsbyImageData.images.fallback.src}`}
		>
			<SchemaProduct
				name={keyword}
				image={data.headerImage.twitterAndProduct.gatsbyImageData.images.fallback.src}
				description={description}
				aggregateOffer
				lowPrice="500"
				highPrice="5000"
				url={`${data.site.siteMetadata.siteUrl}${location.pathname}`}
			/>
			<LandingpageHeader
				headline={{ one: "Webdesign für Zahnärzte:", two: "Entwicklung patientenorientierter Websites" }}
				image={{ data: data.headerImage.header, alt: `${keyword} - Arzt im Portrait` }}
				text={
					<>
						Mit <strong>Webdesign für Zahnärzte</strong> entwickeln wir Dir eine patientenorientierter Website, mit der
						die Patienten automatisch zu Dir finden und Du zudem Zeit sparen kannst. Zudem erhöhen wir durch
						intelligente Websites die Patientenzufriedenheit und Kommunikation.
					</>
				}
				buttonOne={{ text: "Kostenloses Beratungsgespräch vereinbaren", to: `${location.pathname}anfragen/` }}
				pages={[
					{ name: "Leistungen", to: "/leistungen/" },
					{
						name: keyword,
						to: location.pathname,
					},
				]}
			/>

			<KeySellingpoints
				sellingponts={[
					{
						headline: "Das Image der Praxis bewahren oder fördern",
						text: [
							"Wenn Du als Zahnarzt eine neue Website erstellst, hast Du viele Vorteile, die Dir auf den ersten Blick gar nicht bewusst sein mögen. Du möchtest einen sauberen und zuverlässigen Eindruck bei Deinen Patienten hinterlassen? Dann ist eine professionelle Website in der heutigen Zeit unerlässlich, da sie maßgeblich für das Image der Praxis beiträgt. Eine schlechte Website mit falschen Informationen, welche nur bedingt bedienbar ist, führt dazu, dass Patienten einen ersten schlechten Eindruck von Dir erhalten. Die Website dient als digitale Eintrittstür zu Deiner Praxis, egal ob Du willst oder nicht. Solltest Du keine Website haben, verliert sich der Patient im World Wide Web und landet eventuell auf Seiten, auf denen falsche oder veraltete Informationen über Dich hinterlegt sind.",
						],
						image: data.imageIntro1,
						alt: `${keyword} - Zahnarzt am Empfang`,
						button: {
							to: `${location.pathname}anfragen/`,
							text: "Image verbessern",
						},
					},
					{
						headline: "Zufriedenheit der Patienten und Kommunikation",
						text: [
							"Der Patienten-Zahnarzt-Beziehung ist eine sehr intime Sache. Der Patient übergibt seine Gesundheit in Deine Hände. Aus diesem Grund ist es sehr wichtig, dass Kompetenz und Zuverlässigkeit des Informationsflusses von der Empfangsseite bis hin zur ärztlichen Beratung funktioniert. Allerdings beginnt die Informationsaufnahme des Patienten nicht bei Dir in der Zahnarztpraxis, sondern im Internet. Die Anfahrt, Sprechzeiten und Kernkompetenzen der Praxis müssen schon auf der Website vermittelt werden. Zudem kann eine ordentlich geführte Website den Empfang entlasten und Du sparst zudem Verwaltungsaufwand und kannst Dich mehr auf Deine Patienten konzentrieren.",
						],
						image: data.imageIntro2,
						alt: `${keyword} - Zufriedener Patient`,
						button: {
							to: `${location.pathname}anfragen/`,
							text: "Patientenbeziehung verbessern",
						},
					},
					{
						headline: "Sichtbarkeit und Patientenakquise",
						text: [
							"Wenn Du auf der Suche nach Patienten bist, kann eine Website für Zahnärzte sehr nützlich sein. Wir sind spezialisiert auf Suchmaschinenoptimierung und helfen Dir, Deine Website so zu gestalten, dass sie bei den Suchmaschinen ganz oben erscheint. Dies hat zur Folge, dass immer mehr Patienten auf Dich aufmerksam werden, ohne dass Du dauerhaft Werbeausgaben hast. Diese Art des Marketings kennzeichnet sich durch Nachhaltigkeit und hält bei einer einmaligen Investition meist mehrere Jahre.",
							"Suchst Du Patienten, die bei privaten Krankenkassen versichert sind? Dann können wir auch in diesem Fall helfen. Durch intelligentes Targeting der Zielgruppen, die sich überwiegend in den privaten Krankenkassen befinden, können wir so die richtigen Patienten zu Dir bringen.",
						],
						image: data.imageIntro3,
						alt: `${keyword} - Patient sucht online einen Termin`,
						button: {
							to: `${location.pathname}anfragen/`,
							text: "Patientenakquise fördern",
						},
					},
				]}
			/>

			<FeatureList
				headline={`${keyword}: Unser Leistungsspektrum`}
				text={`Uns sind ganzheitliche Lösungen für unsere Kunden über aus wichtig. Daher bieten wir auch für den Bereich ${keyword} eine ganzheitliche Lösung für Deine Website. Ein einfacher Auftrag genügt und wir kümmern uns um die erfolgreiche Projektumsetzung und darüber hinaus.`}
				features={data.site.siteMetadata.globalFeautureList.webdesign}
			/>

			<SeoText
				headline="Was ist gutes Webdesign für Zahnärzte?"
				intro={[
					"Für Dich als Zahnarzt haben wir uns bestimmte Lösungen ausgedacht, die besonders wertvoll für Dich sein könnten. Daher koppeln wir unser Dienstleistungsspektrum mit folgenden Leistungspunkten:",
				]}
				text={[
					{
						headline: "Modern, inhaltsstark und patientenorientiert",
						text: [
							"Eine gute Homepage brilliert nicht nur durch ein ansprechendes Design. Es stimmt, dass eine moderne Homepage den ersten WOW-Effekt auslösen sollte. Doch der WOW-Effekt ist nur für den ersten Eindruck nützlich. Neben dem Design sind die Inhalte das Herzstück jeder Website. Keine Website überzeugt ausschließlich durch ein schönes Erscheinungsbild. Der Besucher einer Website hat immer ein Bedürfnis, welches befriedigt werden muss. Bei einer Zahnarzthomepage hat der Patient vorwiegend das Bedürfnis nach einer Lösung zu seinem gesundheitlichen Problem. Sollte der Patient Dich nicht kennen, ist eine Auflistung der Kernkompetenzen des Zahnarztes wichtig. Sollte der richtige Zahnarzt gefunden worden sein, ist eine schnelle und einfache Terminfindung für beide Seiten sinnvoll. Natürlich sind die Sprechzeiten und die Adresse unabdingbar auf der Website, aber wäre es nicht sinnvoll auch eine automatische Terminfindung in die Homepage einzubauen? So entlastest Du Deinen Empfang und der Patient kann sich direkt einen freien Termin auf der Homepage aussuchen. Zudem hast Du den Vorteil, dass Du der Name, Telefon und E-Mail schwarz auf weiß vorliegen hast. Eine gute Website ist also Patientenorientiert, hilft dabei den richtigen Zahnarzt zu finden und macht die Terminbuchung spielend einfach.",
						],
					},
					{
						headline: "News",
						text: [
							"Die Coronapandemie hat gezeigt, wie wichtig aktuelle Nachrichten für Patienten sein müssen. Als Praxis ist es umso wichtiger, wie Du als Praxis auf bestimmte Umstände reagierst und was bei Dir konkret ansteht. Damit Deine Patienten bezüglich Deiner Praxis immer auf dem neusten Stand sind, ist ein News-System sinnvoll. So kannst Du die Homepage als Sprachrohr zu Deinen Kunden nutzen und informierst ihn bei besonderen Umständen. Zudem kannst Du immer auf die Website verweisen, sollten sich Fragen häufen, die den Betriebsfluss innerhalb der Praxis stören.",
						],
					},
					{
						headline: "Userexperience und Barrierefreiheit",
						text: [
							"Die Website ist, wenn Du alle vorangegangen Punkte beachtest, modern, sichtbar und nützlich. Entscheidend ist nun, dass auch der Patient eine wundervolle Erfahrung auf der Website hat. Alle Website, die länger als zwei Sekunden laden, gelten als sehr langsam. Das ist wichtig, da der Website-Besucher schnell wieder von Websites abspringt, sollten diese nicht rechtzeitig laden. Die Website muss also rasant laden können.",
							"Aber auch die Barrierefreiheit ist gerade für den Gesundheitssektor angebracht. Eine ordentliche Zahnarzt-Website hält alle Standards der „Accessible Rich Internet Applications Suite“ ein. Das bedeutet, dass die Website ohne Maus bedienbar ist und alle Elemente über einen Screenreader und Kontraste für Sehbehinderte sinnvoll gestaltet ist. So wird ermöglicht, dass ein möglichst breiter Zugang zur Website gewährleistet wird.",
						],
					},
					{
						headline: "Userinterface",
						text: [
							"Eine Website im 21. Jahrhundert ist auf allen erdenklichen Endgeräten erreichbar. Webdesigner nennen diesen Ansatz Responsive Webdesign. Dieser Ansatz ermöglicht es, dass eine Website dynamisch wie ein Gummiband auseinandergezogen werden kann, sodass sich das Userinterface der Breite des Monitors oder Fensters anpasst. Zudem ist es wichtig, dass auch alle Elemente klar und deutlich hervorgehoben sind und leicht bedienbar sind. Ein kleiner Button der präzise geklickt werden muss ist frustrierend und dient als schlechtes Beispiel für Webdesign. Achte darauf, dass jedes gestaltete Userinterface nicht nur wunderschön ist, sondern auch sinnvoll gestaltet ist und alles bedienbar ist. Der Besucher der Website muss sich, ohne lange zu suchen, schnell auf der Website zurechtfinden können. Eine sinnvolle und verständnisvolle Struktur der Website hilft enorm.",
						],
					},
				]}
			/>

			<Cta
				title={`${keyword} jetzt anfragen`}
				titleAs="p"
				text="Haben wir Dein Interesse geweckt? Dann fragen gerne ein unverbindliches Gespräch bei uns an und wir schauen, ob wir zueinanderpassen."
				button={`${keyword} anfragen`}
				to={`${location.pathname}anfragen/`}
			/>

			<SeoText
				headline="Worauf achten wir beim Webdesign für Zahnärzte?"
				intro={[
					"Viele der vorangegangenen Punkte sind essenziell für ein gutes Webdesign für Zahnärzte. Doch was macht Lautenschlager Marketing & Entwicklung noch mal anders oder besser als die Konkurrenz? Wir möchten ganzheitliche Lösungen anbieten, ohne langes hin und her. Du sollst Dich auf Deinen Dienstleister verlassen können und Dich auf Deine Patienten konzentrieren, daher gehen wir einen Schritt weiter und bieten neben den voran gegangenen Punkte zu jeder Website folgende Dinge an.",
				]}
				text={[
					{
						headline: "Wahlweise passendes modernes Logo",
						text: [
							"Ein Logo ist das Gesicht nach Draußen zu Deinen Kunden. Jede Praxis, die sich professionell aufstellen will, benötigt ein ordentliches und einmaliges Logo, das einen Wiedererkennungswert bietet. Das Logo ist auch notwendig für jede Website, wie sonst soll der Patient wissen, dass er auf der richtigen Website ist? Solltest Du über kein Logo besitzen, können wir Dir ein Logo, sowie jegliche Geschäftsausstattung entwickeln lassen.",
						],
					},
					{
						headline: "Wahlweise schöne Fotos Deiner Praxis",
						text: [
							"Du hast keine guten Fotos von Deiner Praxis? Eine Website lebt von den Bildern aus der Praxis – egal ob es um Raum- oder Porträtfotos geht. Gerade als professionelle Praxis, die nahbar für seine Patienten sein will, benötigt echte Fotos aus der Praxis. So fühlt sich der Besucher der Website direkt heimisch.",
						],
					},
					{
						headline: "Verständnisvolle und einfache Texte",
						text: [
							"Die Kommunikation zu den Patienten kann oft zu Missverständnissen führen. Um möglichst wenig Reibungsfläche zu den Patienten zu entwickeln, verzichten wir auf komplizierte Texte. Unsere einfachen, aber dennoch fachlich korrekten Texte ermöglichen einen verständnisvollen Duktus.",
						],
					},
					{
						headline: "Website-Pflege",
						text: [
							"Damit die Website nicht in Vergessenheit gerät oder aufgrund von Zeitmangel nicht aktualisiert wird, bieten wir neben normalen Webdesign auch unser Website-Abo an. Mit dieser Dienstleistung möchten wir allen Zahnärzten helfen, die keine Zeit oder Muse haben, sich um die eigene Website zu kümmern. Mit dem Website-Abo buchen unsere Zahnärzte eine Dienstleistung mit Rückenwind. Für einen festen Betrag stehen wir immer zur Verfügung und ändern jegliche Inhalte auf der Website, sollte etwas anstehen. Eine einfache kurze E-Mail an uns reicht und wir machen uns an die Arbeit, die Website zu aktualisieren, ohne lästige Stundenabrechnungen.",
						],
					},
					{
						headline: "Reputationsmanagement bei Bewertungsportalen",
						text: [
							"Zahnärzte werden von Ihren Patienten ständig im Netz bewertet. Öffentliche Plattformen bieten ein Bewertungssystem ein und Praxen werden auch ohne Dein Einverständnis bewertet. Damit Du im richtigen Licht stehst, bieten wir das Reputation-Management an. Mit diesem Paket kümmern wir uns um die Profile auf diesen Seiten und beantworten und pflegen die Bewertungen, die von außerhalb kommen. So kann man auf seine Patienten auch im Netz eingehen und sorgt so für mehr Verständnis für die eigene Praxis.",
						],
					},
					{
						headline: "Verschlüsselte Daten in Deutschland",
						text: [
							"Kunden müssen Dir vertrauen und daher werden alle Daten, die über die Website verarbeitet werden, über eine verschlüsselte Datenverbindung übertragen. Zudem nutzen wir keine ausländischen Datenspeicherorte. Alle Kunden, die bei uns hosten, erhalten einen zuverlässigen und DSGVO-konformen Dienstleister, der Server in Deutschland ansiedelt. So vermeiden wir Datenmissbrauch.",
						],
					},
				]}
			/>
			<About link={`${location.pathname}anfragen/`} keyword={keyword} />
		</Layout>
	);
}

export const query = graphql`
	{
		headerImage: file(
			relativePath: { eq: "pages/leistungen/webdesign-zahnaerzte/webdesign-fuer-zahnaerzte_header.jpg" }
		) {
			header: childImageSharp {
				gatsbyImageData
			}
			facebook: childImageSharp {
				gatsbyImageData(height: 630, width: 1200, formats: JPG, quality: 100)
			}
			twitterAndProduct: childImageSharp {
				gatsbyImageData(aspectRatio: 1, formats: JPG, quality: 100)
			}
		}

		imageIntro1: file(
			relativePath: {
				eq: "pages/leistungen/webdesign-zahnaerzte/webdesign-fuer-zahnaerzte_zahnarzt-bei-der-arbeit.jpg"
			}
		) {
			childImageSharp {
				gatsbyImageData
			}
		}

		imageIntro2: file(
			relativePath: { eq: "pages/leistungen/webdesign-zahnaerzte/webdesign-fuer-zahnaerzte_zufrieden.jpg" }
		) {
			childImageSharp {
				gatsbyImageData
			}
		}

		imageIntro3: file(
			relativePath: { eq: "pages/leistungen/webdesign-zahnaerzte/webdesign-fuer-zahnaerzte_suche.jpg" }
		) {
			childImageSharp {
				gatsbyImageData
			}
		}

		site {
			siteMetadata {
				siteUrl
				globalFeautureList {
					webdesign {
						headline
						text
					}
				}
			}
		}
	}
`;
