import React from "react";
import Button from "../common/Button";
import Container from "../common/Container";
import P from "~components/typography/P";

interface Props {
	title: string;
	titleAs?: string;
	text: string;
	button: string;
	onClick?: React.MouseEventHandler;
	anchor?: string;
	to?: string;
	href?: string;
	target?: string;
	rel?: string;
}

const Cta: React.FC<Props> = ({
	title,
	titleAs = "h2",
	text,
	button,
	onClick = null,
	anchor = null,
	to = null,
	href = null,
	target = null,
	rel = null,
}) => {
	return (
		<Container>
			<div className="bg-pattern mx-auto max-w-2xl p-3">
				<div className="overflow-hidden bg-black p-8 text-center">
					<Headline as={titleAs}>{title}</Headline>
					<P textColor="text-white" className="mb-5">
						{text}
					</P>
					<Button
						text={button}
						white
						onClick={onClick || undefined}
						anchor={anchor || undefined}
						to={to || undefined}
						href={href || undefined}
						target={target || undefined}
						rel={rel || undefined}
					/>
				</div>
			</div>
		</Container>
	);
};

export default Cta;

interface PropsHeadline {
	as: string;
	children: React.ReactNode;
}

const Headline: React.FC<PropsHeadline> = ({ as, children }) => {
	const classes = `mb-3 font-display text-xl text-white md:text-2xl`;
	return React.createElement(as, { className: classes }, children);
};
